import {
  Center,
  Flex,
  Image,
  Text,
  VStack,
  useMediaQuery,
} from "@chakra-ui/react";
import WppButton from "./wppbutton";
import { motion } from "framer-motion";
import logooab from "../assets/logooab.webp";
export default function SectionA() {
  const [isMobile] = useMediaQuery("(max-width: 1024px)");
  return (
    <Center
      w="full"
      h={{ base: "600px", lg: "720px", "2xl": "750px" }}
      backgroundImage="url('/assets/fundo-a.webp')"
      backgroundSize={{ base: "120vw", lg: "cover" }}
      backgroundPosition={"top"}
      backgroundRepeat={"no-repeat"}
      pt={{ base: "100px", md: 0 }}
    >
      <Center
        w={"full"}
        h={{ base: "700px", lg: "full" }}
        backgroundImage={{
          base: "linear-gradient(transparent 0%, transparent 30%, black 40%, black 100%)",
          lg: "",
        }}
        backgroundSize={"cover"}
      >
        <VStack
          w="full"
          h={{ base: "auto" }}
          maxW="840px"
          alignItems={"center"}
          p={{ base: "42px 12px", lg: "22px 12px" }}
          textAlign={"center"}
          spacing={8}
          color="white"
          mb={{ base: "-100px", lg: 0 }}
        >
          <Text
            as={motion.div}
            initial={{ x: -100, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            lineHeight={{ base: "30px", lg: "44px", "2xl": "60px" }}
            fontSize={{ base: "24px", lg: "40px", "2xl": "50px" }}
            pl="4px"
            mb={{ base: "10px", "2xl": "20px" }}
            maxW="420px"
            fontWeight={"medium"}
            // fontFamily={"Poppins"}
          >
            Deseja entrar com pedido de
            <br />
            <b>
              <Text as="i" color="brand.300">
                Pensão Alimentícia?
              </Text>
            </b>
          </Text>
          <Text>
            Acredite, contar com um Advogado <b>Especialista</b> em Pensão
            Alimentícia fará toda a diferença no processo. Estou aqui para te
            ajudar a<b>&nbsp;minimizar</b> os impactos emocionais e{" "}
            <b>proteger</b> os seus direitos.
          </Text>
          <Flex direction={"column"}>
            <WppButton
              title="FALAR COM ADVOGADO"
              showCirculeButton
              customBgColor="brand.300"
              fontWeight={"bold"}
              mb="4px"
              description="Atendimento em todo Brasil 100% online no WhatsApp"
            />
          </Flex>
          <Text
            as={motion.div}
            initial={{ x: -100, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            lineHeight={{ base: "16px", lg: "28px", "2xl": "30px" }}
            fontSize={{ base: "11px", lg: "20px", "2xl": "20px" }}
            // mb={{ base: "10px", lg: "170px", "2xl": "220px" }}
            // px="4px"
            // noOfLines={2}
            textOverflow={"clip"}
          >
            <span>Escritório recomendado pelo GOOGLE</span>
            <br />
            <Text as="b" fontSize={{ base: "10px", xl: "18px" }}>
              com mais de{" "}
              <Text as="span" color="brand.300">
                10 anos
              </Text>{" "}
              de Experiência e mais de{" "}
              <Text as="span" color="brand.300">
                5000 clientes{" "}
              </Text>
              atendidos.
            </Text>
          </Text>
          {isMobile && (
            <Image
              src={logooab}
              w={"230px"}
              height={"170px"}
              loading="lazy"
              objectFit={"contain"}
              alt="logo com marca da ordem dos advogados(OAB)"
            />
          )}
        </VStack>
      </Center>
    </Center>
  );
}
