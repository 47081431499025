import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Flex,
  Heading,
} from "@chakra-ui/react";

type AccordionItemProps = {
  title: string;
  panel: JSX.Element;
};

const faqList = [
  {
    question: "Como funciona o atendimento?",
    answer: (
      <>
        <p>
          Depois de tocar nos botões de contato ao longo da página, você será
          redirecionado para o WhatsApp comercial do escritório para conversar
          com um advogado da nossa equipe.
        </p>
        <p>
          Esse profissional conversará com você sobre seu problema para
          entendê-lo e concluir sobre a viabilidade de sua atuação.
        </p>
        <p>
          Mais elementos serão solicitados se considerarmos necessários para
          concluir essa análise.
        </p>
        <p>Finalmente, propomos a solução para o seu problema.</p>
      </>
    ),
  },
  {
    question: "Por que não confiar no advogado do seu ex?",
    answer: (
      <>
        <p>
          “Vamos resolver logo isso. Deixe que eu pago o advogado.” = Golpe por
          cima de golpe!
          <b>
            O advogado defende o interesse de quem está lhe contratando. MUITO
            CUIDADO COM AS CLÁUSULAS DO ACORDO.
          </b>
        </p>
        <p>
          O melhor investimento que você fará na sua vida (confie em mim!) é
          investir em um advogado que irá te representar e que tem a sua
          confiança. E estou te dizendo para fazer isso no divórcio amigável
          mesmo…
        </p>
        <p>
          São incontáveis vezes que me deparo com{" "}
          <b>
            péssimos acordos realizados pelo profissional que foi escolhido
            apenas pelo ex-marido. O mais interessante é que o “bonito” sempre é
            o favorecido nestes golpes revestidos de “acordo”.
          </b>
        </p>
      </>
    ),
  },
  {
    question: "O que é pensão alimentícia?",
    answer: (
      <>
        São todos os gastos da criança com:
        <ol>
          <li>
            <b></b>MERCADO (inclui alimentos no geral, frutas, verduras, arroz,
            feijão, etc);
          </li>
          <li>
            <b>MORADIA</b> (Valor do aluguel, condomínio, financiamento de casa/
            apartamento, etc);
          </li>
          <li>
            <b>HIGIENE</b> (itens básicos de higiene, que também incluem
            fraldas, lenços, produtos para pele, e no caso das meninas,
            maquiagem e derivados);
          </li>
          <li>
            <b>EDUCAÇÃO</b> (mensalidade escolar, rematrícula - divide por 12 -,
            uniforme, taxa anual, livros e material escolar)
          </li>
          <li>
            <b>ATIVIDADES EXTRACURRICULARES</b> (futebol, inglês, balé, natação,
            luta, etc.);
          </li>
          <li>
            <b>VESTUÁRIO</b> (roupas e calçados);
          </li>
          <li>
            <b></b>DESPESAS DE CASA (energia elétrica, água, gás, internet,
            eventuais funcionários, jardinagem etc.) - Somar e dividir pela
            quantidade de moradores do local;
          </li>
          <li>
            <b>SAÚDE</b> (plano de saúde, despesas médicas, farmácia);
          </li>
          <li>
            <b>LAZER</b> (aqui podem entrar saídas (cinema e teatro), presentes
            para festas de aniversário, parque de diversões, viagens, entre
            outros);
          </li>
          <li>
            <b>EXTRAS</b> (tudo aquilo que a criança tiver de gasto rotineiro, e
            não esteja pontuado nos itens acima);
          </li>
        </ol>
      </>
    ),
  },
  {
    question: "Por que não fazer acordo verbal de pensão alimentícia",
    answer: (
      <>
        <p>
          Como profissional posso garantir que esse é o maior erro que as mães
          cometem em relação à pensão alimentícia dos filhos, pois um acordo “de
          boca” não possui validade jurídica nenhuma e{" "}
          <b>não possui nenhuma garantia de cumprimento</b> da obrigação
          alimentar.
        </p>
        <p>
          Assim, caso a pensão alimentícia não seja paga pelo genitor, não
          haverá meios para lhe cobrar judicialmente e a genitora não poderá
          recorrer a ninguém para exigir o cumprimento de pagamento da pensão
          alimentícia.
        </p>
        <p>
          Outro ponto que merece ser destacado, é que, nesse tipo de “acordo”,
          nunca é levado em conta a real capacidade contributiva do pai, tendo
          em vista que ele poderá omitir seus reais rendimentos para pagar
          valores ínfimos ou muito abaixo da sua real capacidade financeira.
        </p>
      </>
    ),
  },
  {
    question: "Fiquei desempregado preciso pagar pensão?",
    answer: (
      <>
        <p>
          SIM! O responsável pelo pagamento da pensão não pode simplesmente
          parar de pagar, cabendo inclusive pedido de prisão. É possível entrar
          com um pedido de revisão do valor.
        </p>
        <p>
          Seu filho não vai parar de comer, só́ porque você está desempregado!
          Não existe nada na lei que permita o não pagamento da pensão em casos
          de desemprego.
        </p>
      </>
    ),
  },
  {
    question: "Meu filho completou 18 anos, posso parar de pagar a pensão?",
    answer: (
      <>
        A pensão alimentícia
        <b> não é cancelada automaticamente com a maioridade!</b>
        Sempre será́ necessário ingressar com uma{" "}
        <b>
          ação de exoneração de alimentos para que um juiz determine o fim do
          pagamento da pensão
        </b>
        , e somente com essa decisão é que podé parar de pagar.
      </>
    ),
  },

  {
    question: "Pensão alimentícia é sempre 30%?",
    answer: (
      <>
        <p>
          NÃO. Aquela história de que toda pensão é sempre fixada em 30% é
          mentira. Não existe um valor fixo definido na lei. O valor a ser pago
          deve ter como norte dois parâmetros:{" "}
          <b>Necessidade do filho e a possibilidade de quem vai pagar.</b>
        </p>
        <p>
          Em resumo: não há uma fórmula perfeita. Assim, deve ser analisado e
          calculado quais são os gastos que a criança necessita, por exemplo,
          transporte escolar, plano de saúde etc. e as possibilidades de quem
          tem o dever de pagar. Esses gastos deverão ser arcados por AMBOS os
          pais, na medida da possibilidade econômica de cada um deles.
        </p>
      </>
    ),
  },
  {
    question: "Posso pedir pensão durante a gravidez?",
    answer: (
      <>
        <p>
          SIM.{" "}
          <b>
            Qualquer mulher grávida que precisa de auxílio financeiro para a
            gestação e para o parto pode buscar na Justiça apoio do
            ex-companheiro
          </b>
          . O dinheiro é pra cobrir despesas com exames, medicamentos,
          alimentação especial, assistência médica e psicológica, parto, enxoval
          do bebê e outros gastos que o juiz considerar necessários.
        </p>
        <p>
          <b>Ter algum exame que comprove que você está grávida</b>;
        </p>
        <p>
          <b>Provas que indiquem quem é o pai</b>, por exemplo, mensagens
          trocadas no WhatsApp ou e-mail, fotos juntas que demonstrem que houve
          um relacionamento, mesmo que breve e sem compromisso;
        </p>
        <p>
          Informações sobre o pai: nome completo, endereço, telefone ou
          WhatsApp, o nome e endereço do trabalho, quanto mais informação
          melhor.
        </p>
        <p>
          Prova dos gastos futuros com a gestação, como por exemplo, orçamento
          de enxovais, receitas de remédios, solicitações de exames feitas pelos
          médicos.
        </p>
        <p>
          Com todas essas informações em mãos, você irá procurar um advogado
          familiarista que analisará o seu caso e fará o pedido da pensão
          alimentícia na justiça para você.
        </p>
      </>
    ),
  },
];

const CustomAccordionItem: React.FC<AccordionItemProps> = ({
  title,
  panel,
}) => {
  return (
    <AccordionItem
      borderTopWidth={0}
      borderBottomWidth={0}
      borderColor="transparent"
    >
      <h2>
        <AccordionButton sx={styles.accordionBtn}>
          {title}
          <AccordionIcon />
        </AccordionButton>
      </h2>
      <AccordionPanel w="full" sx={styles.accordionPanel} borderBottomWidth={0}>
        {panel}
      </AccordionPanel>
    </AccordionItem>
  );
};

export default function SectionE() {
  return (
    <Flex
      fontWeight={300}
      alignItems={{ base: "center", lg: "center" }}
      justifyContent={{ base: "center" }}
      fontSize={{ base: "12px", lg: "20px" }}
      direction={"column"}
      bg="black"
      color="white"
      p={{ base: "20px" }}
    >
      <Heading
        fontSize={{ base: "2xl", lg: "4xl", "2xl": "4xl" }}
        fontWeight={"bold"}
        alignSelf={"center"}
        textAlign={"left"}
        mb="0px"
        fontFamily={"Poppins"}
      >
        PERGUNTAS FREQUENTES
      </Heading>
      <Accordion
        w="full"
        maxW={{ base: "100%", lg: "900px" }}
        allowToggle
        my={{ base: "12px", lg: "20px" }}
        size={"lg"}
      >
        {faqList.map(({ question, answer }, index) => (
          <CustomAccordionItem key={index} title={question} panel={answer} />
        ))}
      </Accordion>
    </Flex>
  );
}

const styles = {
  accordionBtn: {
    w: "100%",
    fontFamily: "Poppins",
    textAlign: { base: "left", lg: "left" },
    justifyContent: { base: "space-between" },
    border: "1px solid #ffb600",
    mb: "4px",
    mt: "12px",
    px: {
      base: "12px",
    },
    fontSize: {
      base: "12px",
      lg: "20px",
    },
    py: {
      base: "2px",
      lg: "6px",
    },
  },
  accordionPanel: {
    py: {
      base: "4px",
      lg: "12px",
    },
    px: { base: "22px", lg: "42px" },
    // maxH: {
    //   base: "100px",
    //   lg: "200px",
    // },
    fontSize: { base: "12px", lg: "22px" },
    overflowY: "auto",
  },
};
