import { Route, Routes } from "react-router-dom";
import LpFamilia from "./pages/LpFamilia";
import ErrorPage from "./pages/ErrorPage";
import LpPensao from "./pages/LpPensao";

function RouterElement() {
  return (
    // <BrowserRouter>
    <Routes>
      <Route index path="/lp-familia" element={<LpFamilia />} />
      <Route index path="/lp-pensao" element={<LpPensao />} />
      <Route path="*" element={<ErrorPage />} />
    </Routes>
    // </BrowserRouter>
  );
}

export default RouterElement;
