import { Center, Heading, Image, Text, VStack } from "@chakra-ui/react";
import { styles } from "../styles";
import { motion } from "framer-motion";
import iconePensao from "../assets/alimentos/alimentos-a.webp";
import iconeDivorcio from "../assets/alimentos/alimenos-b.webp";
import iconeGuarda from "../assets/alimentos/alimentos-c.webp";
import iconeInventario from "../assets/alimentos/alimentos-d.webp";
export default function SectionB() {
  return (
    <Center
      id="direitos"
      bg="#242424"
      p={{ base: "40px 18px", lg: "40px 100px 80px 100px" }}
      fontWeight={300}
      color="white"
      flexDir={"column"}
      fontFamily={"Poppins"}
      mt="-1px"
    >
      <Heading
        fontSize={{ base: "md", lg: "2xl" }}
        mb="18px"
        maxW="900px"
        textAlign={"center"}
        fontWeight={"normal"}
        fontFamily={"Poppins"}
      >
        <span style={{ color: "#F7A933" }}>
          Não importa qual seja o problema que você está enfrentando,
        </span>{" "}
        seja divórcio, guarda dos filhos, pensão alimentícia, paternidade ou
        violência doméstica...{" "}
        <span style={{ color: "#F7A933" }}>Você não está sozinho!</span>
      </Heading>
      <Heading fontSize={"xl"} fontWeight={"medium"} my="20px">
        CONTE COMIGO EM CASO DE:
      </Heading>
      <VStack
        spacing={{ base: "0px", lg: 3 }}
        my="20px"
        fontSize={{ base: "sm", lg: "3xl" }}
        // px={{ base: "12px", lg: undefined }}
      >
        <Center
          sx={styles.listItem}
          as={motion.div}
          initial={{ x: -100, opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
        >
          <Center sx={styles.listItemIconImage}>
            <Image
              src={iconePensao}
              loading="lazy"
              alt="Ação de alimentos"
              w="auto"
              height="auto"
            />
          </Center>
          <Text>Ação de alimentos</Text>
        </Center>
        <Center
          sx={styles.listItem}
          as={motion.div}
          initial={{ x: -100, opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
        >
          <Center sx={styles.listItemIconImage}>
            <Image
              src={iconeDivorcio}
              loading="lazy"
              alt="Revisional de alimentos"
              w="auto"
              height="auto"
            />
          </Center>
          <Text>Revisional de alimentos</Text>
        </Center>
        <Center
          sx={styles.listItem}
          as={motion.div}
          initial={{ x: -100, opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
        >
          <Center sx={styles.listItemIconImage}>
            <Image
              src={iconeGuarda}
              loading="lazy"
              alt="Exoneração de alimentos"
              w="auto"
              height="auto"
            />
          </Center>
          <Text>Exoneração de alimentos</Text>
        </Center>
        <Center
          sx={styles.listItem}
          as={motion.div}
          initial={{ x: -100, opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
        >
          <Center sx={styles.listItemIconImage}>
            <Image
              src={iconeInventario}
              loading="lazy"
              alt="Execução de alimentos"
              w="auto"
              height="auto"
            />
          </Center>
          <Text>Execução de alimentos</Text>
        </Center>
      </VStack>
    </Center>
  );
}
