import { extendTheme } from "@chakra-ui/react";

// 3. extend the theme
export const theme = extendTheme({
  initialColorMode: "light",
  useSystemColorMode: false,
  colors: {
    brand: {
      200: "#F7A933",
      300: "#E58300",
      400: "#7EC34A",
    },
  },
});
