import { Center, Flex, Heading, Image, VStack, Text } from "@chakra-ui/react";
import { ReactElement } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import escritorioA from "../assets/escritorio-a.webp";
import escritorioB from "../assets/escritorio-b.webp";
import iconeAdvogado from "../assets/lawyer-icon.webp";
import iconeBalance from "../assets/balance-icon.webp";
import iconeCel from "../assets/cel-icon.webp";
interface IItems {
  src: string;
  description: ReactElement<any, any>;
  alt: string;
}

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

const items: IItems[] = [
  {
    src: iconeAdvogado,
    description: (
      <p>
        Atendimento rápido com o processo protocolado em até 72 horas após a
        entrega da sua documentação.
      </p>
    ),
    alt: "Advogado icone",
  },
  {
    src: iconeBalance,
    description: (
      <p>
        <b>Facilidade Total!</b> Você não precisa sair de casa para resolver seu
        problema. Sua documentação assinada pode ser enviada pelo WhatsApp, com
        sigilo e segurança.
      </p>
    ),
    alt: "Balança icone",
  },
  {
    src: iconeCel,
    description: (
      <p>
        Advogado com mais de <b>10 anos de experiência</b> e mais de 5000
        clientes atendidos.
      </p>
    ),
    alt: "Celular icone",
  },
];
export default function SectionC() {
  return (
    <Center bg="#242424" p="20px" borderBottom={"30px solid #e58300"}>
      <VStack>
        <Heading
          fontFamily={"Poppins"}
          color="white"
          textAlign={"center"}
          letterSpacing={"0px"}
          fontSize={{ base: "2xl", xl: "4xl" }}
          maxW={{ base: "260px", xl: "400px" }}
          my="22px"
        >
          UM ESCRITÓRIO QUE LUTA POR VOCÊ
        </Heading>
        <Flex
          w={{ base: "full", xl: "1000px" }}
          maxW="1000px"
          direction={{ base: "column", xl: "row" }}
          justifyContent={{ base: "center" }}
        >
          <Carousel
            // ref={ref}

            swipeable
            draggable
            showDots={true}
            responsive={responsive}
            infinite={true}
            autoPlay
            autoPlaySpeed={6000}
            keyBoardControl={true}
            customTransition="all .5"
            transitionDuration={500}
            containerClass="carousel-container-b"
            removeArrowOnDeviceType={["tablet", "mobile"]}
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-40-px"
            // id="google-reviews"
          >
            <Center
              w={{ base: "100vw", xl: "100%" }}
              h="100%"
              objectFit={"contain"}
            >
              <Image
                src={escritorioA}
                loading="lazy"
                alt="Escritório externo"
                w="auto"
                height="auto"
              />
            </Center>
            <Center
              w={{ base: "100vw", xl: "100%" }}
              h="100%"
              objectFit={"contain"}
            >
              <Image
                src={escritorioB}
                loading="lazy"
                alt="Escritório interno e hernandes"
                w="auto"
                height="auto"
              />
            </Center>
          </Carousel>
        </Flex>
        <Text
          color="white"
          mb="42px"
          w="100%"
          maxW={{ base: "95vw", xl: "1100px" }}
          textAlign={"center"}
        >
          Quando se trata de questões de família, é <b>importantíssimo</b> que
          você busque auxílio de um especialista. Você pode contar com o{" "}
          <b>Dr. Hernandes</b> para defender seus direitos.
        </Text>
        <Flex
          direction={{ base: "column", xl: "row" }}
          alignItems={{ base: "center" }}
          w="full"
          maxW="1000px"
          marginBottom="-140px"
        >
          {items.map((item) => (
            <VStack
              key={item.src}
              w={{ base: "80%", xl: "30%" }}
              maxW={{ base: "95vw", xl: "300px" }}
              color="white"
              mx="20px"
              my="40px"
              justifyContent={"center"}
              textAlign={{ base: "justify", xl: "justify" }}
              textIndent={"8px"}
              bg="#171717"
              borderTop={"4px solid #e58300"}
              h={{ base: "auto" }}
            >
              <Flex
                objectFit={"contain"}
                w={"100%"}
                alignSelf={{ base: "center", xl: "flex-start" }}
                mt="-50px"
                h="100px"
              >
                <Image
                  src={item.src}
                  objectFit={"contain"}
                  alt={item.alt}
                  loading="lazy"
                  w="auto"
                  height="auto"
                />
              </Flex>
              <Center p="12px">{item.description}</Center>
            </VStack>
          ))}
        </Flex>
      </VStack>
    </Center>
  );
}
