import { Center, Flex, Heading, Text, VStack } from "@chakra-ui/react";
import WppButton from "./wppbutton";

export default function SectionF() {
  return (
    <Center py={{ base: "20px", lg: "40px" }} px="20px" bg="brand.300">
      <VStack
        alignItems={"center"}
        spacing={{ base: "20px", lg: "20px" }}
        textAlign={"center"}
      >
        <Heading
          fontFamily={"Poppins"}
          fontWeight={"bold"}
          fontSize={{ base: "28px", lg: "36px" }}
          color="black"
        >
          Realize uma consulta 100% online
        </Heading>
        <Flex direction={"column"} maxW="800px">
          <Text fontSize={{ base: "16px", lg: "24px" }}>
            Entre em contato via WhatsApp e receba atendimento virtual desde a
            primeira consulta até o fim do processo.
          </Text>
        </Flex>
        <Flex direction={"column"}>
          <WppButton
            title="FALAR COM ADVOGADO"
            showCirculeButton
            customBgColor="brand.200"
            fontWeight={"bold"}
            description="Atendimento em todo Brasil 100% online no WhatsApp"
          />
        </Flex>
      </VStack>
    </Center>
  );
}
