import { PhoneIcon } from "@chakra-ui/icons";
import { Button, ButtonProps, Icon, Link, Text } from "@chakra-ui/react";
import { motion } from "framer-motion";

interface IWppButton extends ButtonProps {
  title: string;
  customBgColor?: string;
  showCirculeButton?: boolean;
  description?: string;
}

export default function WppButton({
  title,
  showCirculeButton,
  customBgColor,
  description,
  ...rest
}: IWppButton) {
  return (
    <motion.div
      whileHover={{ scale: 1.05 }}
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Button
        colorScheme={"whatsapp"}
        bg="green.500"
        size={{ base: "md", lg: "lg" }}
        w="max-content"
        fontWeight={300}
        py={{ base: "30px", md: "34px", xl: "40px" }}
        px={{ base: "0px", xl: "12px" }}
        color="white"
        leftIcon={
          showCirculeButton ? (
            <span>
              <Button
                id={`circle-wpp-button-${Math.random() * 1000}`}
                borderWidth={"3px"}
                borderColor={"white"}
                size="sm"
                bg="green.400"
                h={{ base: "40px", lg: "60px" }}
                w={{ base: "40px", lg: "60px" }}
                _hover={{
                  transform: "scale(1.05)",
                }}
                zIndex={9999}
                borderRadius={"full"}
              >
                <Icon
                  as={PhoneIcon}
                  color="white"
                  h={{ base: "16px", lg: "22px" }}
                  w={{ base: "16px", lg: "22px" }}
                />
              </Button>
            </span>
          ) : (
            <></>
          )
        }
        as={Link}
        isExternal
        href="https://api.whatsapp.com/send?phone=5565999137973&text=Ol%C3%A1!%20Gostaria%20de%20falar%20sobre%20Pens%C3%A3o%20aliment%C3%ADcia"
        {...rest}
      >
        <Text
          fontSize={
            showCirculeButton
              ? { base: "16px", lg: "24px" }
              : { base: "13px", lg: "24px" }
          }
        >
          {title}
        </Text>
      </Button>
      {description && (
        <Text pt="4px" fontSize={{ base: "12px", xl: "16px" }}>
          {description}
        </Text>
      )}
    </motion.div>
  );
}
