import { Center, Flex, Heading, Image, Text, VStack } from "@chakra-ui/react";
import iconeChain from "../assets/chain-icon.webp";
import iconeMoney from "../assets/money-icon.webp";
import iconePeople from "../assets/people-icon.webp";

interface IItems {
  src: string;
  title: string;
  description: string;
}

const items: IItems[] = [
  {
    src: iconeChain,
    title: "DEVOLVEMOS A SUA LIBERDADE",
    description:
      "Nos casos em que você decide  pela separação, divórcio ou dissolução de união estável.",
  },
  {
    src: iconeMoney,
    title: "PROTEGEMOS O SEU PATRIMÔNIO",
    description:
      "Defendemos a sua parte no patrimônio gerado enquanto morou com seu (ou sua) ex.",
  },
  {
    src: iconePeople,
    title: "GARANTIMOS O BEM ESTAR DO SEU FILHO",
    description:
      "Estabelecendo onde a criança irá morar, com quem a guarda ficará e quais os horários de visita.",
  },
];

export default function SectionH() {
  return (
    <Center bg="black" py="40px">
      <Flex
        direction={{ base: "column", xl: "row" }}
        alignItems={{ base: "center" }}
        w="full"
        maxW="1000px"
      >
        {items.map((item) => (
          <VStack
            key={item.src}
            w={{ base: "60%", xl: "30%" }}
            color="white"
            m="20px"
            justifyContent={"center"}
            textAlign={{ base: "center", xl: "justify" }}
          >
            <Flex
              objectFit={"contain"}
              w={"50%"}
              alignSelf={{ base: "center", xl: "flex-start" }}
            >
              <Image
                src={item.src}
                alt={item.title}
                loading="lazy"
                w="auto"
                height="auto"
              />
            </Flex>
            <Heading
              fontSize={{ base: "20px", xl: "28px" }}
              fontFamily={"Poppins"}
            >
              {item.title}
            </Heading>
            <Text fontSize={{ base: "16px", xl: "18px" }}>
              {item.description}
            </Text>
          </VStack>
        ))}
      </Flex>
    </Center>
  );
}
