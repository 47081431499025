import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Flex,
  Heading,
  Text,
} from "@chakra-ui/react";

export default function SectionE() {
  return (
    <Flex
      fontWeight={300}
      alignItems={{ base: "center", lg: "center" }}
      justifyContent={{ base: "center" }}
      fontSize={{ base: "12px", lg: "20px" }}
      direction={"column"}
      bg="black"
      color="white"
      p={{ base: "20px" }}
    >
      <Heading
        fontSize={{ base: "2xl", lg: "4xl", "2xl": "4xl" }}
        fontWeight={"bold"}
        alignSelf={"center"}
        textAlign={"left"}
        mb="0px"
        fontFamily={"Poppins"}
      >
        PERGUNTAS FREQUENTES
      </Heading>
      <Accordion
        w="full"
        maxW={{ base: "100%", lg: "900px" }}
        allowToggle
        my={{ base: "12px", lg: "20px" }}
        size={"lg"}
      >
        <AccordionItem w="full" borderTopWidth={0}>
          <h2>
            <AccordionButton sx={styles.accordionBtn}>
              Como funciona o atendimento?
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel w="full" sx={styles.accordionPanel}>
            <Text>
              Depois de tocar nos botões de contato ao longo da página, você
              será redirecionado para o WhatsApp comercial do escritório para
              conversar com um advogado da nossa equipe.
            </Text>
            <Text>
              Esse profissional conversará com você sobre seu problema para
              entendê-lo e concluir sobre a viabilidade de sua atuação. Mais
              elementos serão solicitados se considerarmos necessários para
              concluir essa análise.
            </Text>
            <Text>Finalmente, propomos a solução para o seu problema.</Text>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem borderTopWidth={0}>
          <h2>
            <AccordionButton sx={styles.accordionBtn}>
              Por que contratar um Especialista em Direito de Família?
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel sx={styles.accordionPanel}>
            <Text>
              Um especialista pode e vai lhe trazer a orientação correta e, pode
              lhe dar o melhor atendimento possível, podendo ser crucial no
              momento de se ganhar ou perder uma demanda, ou no caso de uma
              orientação equivocada, trazer muita dor de cabeça.
            </Text>
            <Text>
              Um advogado Especialista em Direito de Família traz muitas
              particularidades e lida com questões mais sensíveis do cliente,
              que demandam extrema paciência e atenção as pessoas envolvidas no
              caso.
            </Text>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem borderTopWidth={0}>
          <h2>
            <AccordionButton sx={styles.accordionBtn}>
              Por que não confiar no advogado do seu ex?
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel sx={styles.accordionPanel}>
            <Text>
              “Vamos resolver logo isso. Deixe que eu pago o advogado.” = Golpe
              por cima de golpe!
            </Text>
            <Text>
              O melhor investimento que você fará na sua vida (confie em mim!) é
              investir em um advogado que irá te representar e que tem a sua
              confiança. E estou te dizendo para fazer isso no divórcio amigável
              mesmo…
            </Text>
            <Text>
              São incontáveis vezes que me deparo com péssimos acordos
              realizados pelo profissional que foi escolhido apenas pelo
              ex-marido. O mais interessante é que o “bonito” sempre é o
              favorecido nestes golpes revestidos de “acordo”.
            </Text>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem borderTopWidth={0}>
          <h2>
            <AccordionButton sx={styles.accordionBtn}>
              Se eu sair da casa perco meus direitos?
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel sx={styles.accordionPanel}>
            <Text>
              NÃO! Mesmo se um dos cônjuges tiver infringido as maiores regras
              de um casamento, continuará tendo intacto o direito à sua cota na
              partilha de bens (meação).
            </Text>
            <Text>
              Seria um absurdo se perdesse. Já imaginou uma mulher que apanha do
              marido todos os dias e resolve sair do lar? Quem teria que perder
              direitos é o cônjuge agressor! Eu sempre recomendo sair da casa e
              já entrar com o processo.
            </Text>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem borderTopWidth={0}>
          <h2>
            <AccordionButton sx={styles.accordionBtn}>
              Posso deixar o filho com o pai por um tempo, e pegar depois?
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel sx={styles.accordionPanel}>
            <Text>
              É preciso alertar as mães que não estão convivendo com os filhos,
              seja porque passou os cuidados aos avós ou pai, que será́ sempre
              mais difícil recuperar a guarda, já que isso levaria total
              alteração da rotina do menor, o que não é algo desejável.
            </Text>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem borderTopWidth={0} borderBottomColor={"transparent"}>
          <h2>
            <AccordionButton sx={styles.accordionBtn}>
              Meu filho completou 18 anos, posso parar de pagar a pensão?
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel sx={styles.accordionPanel}>
            NÃO. A pensão alimentícia não é cancelada automaticamente com a
            maioridade! Sempre será necessário ingressar com uma ação de
            exoneração de alimentos para que um juiz determine o fim do
            pagamento da pensão, e somente com essa decisão é que poderá parar
            de pagar.
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem borderTopWidth={0} borderBottomColor={"transparent"}>
          <h2>
            <AccordionButton sx={styles.accordionBtn}>
              Pensão alimentícia é sempre 30%?
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel sx={styles.accordionPanel}>
            Não há uma fórmula matemática ou uma porcentagem fixa para calcular
            o valor da pensão alimentícia. O cálculo é feito com base no binômio
            necessidade x possibilidade, ou seja, leva-se em conta as despesas
            de quem recebe e a renda de quem paga.
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem borderTopWidth={0} borderBottomColor={"transparent"}>
          <h2>
            <AccordionButton sx={styles.accordionBtn}>
              Quem não paga pensão, tem direito de visitar o filho?
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel sx={styles.accordionPanel}>
            <Text>
              SIM! Não existe o menor embasamento legal para a mãe impedir o pai
              de ver o filho só porque ele não pagou a pensão. Não existe
              relação entre o dever de pagar pensão alimentícia, e o direito de
              visita**. Não se “compra” o direito de visitar seu filho pagando
              pensão**.
            </Text>
            <Text>
              O direito de visitação não é só do pai, mas é da criança também. O
              que faz com que a visitação tenha um caráter interessante, pois
              para o adulto ela é um direito, mas, também, um dever.
            </Text>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem borderTopWidth={0} borderBottomColor={"transparent"}>
          <h2>
            <AccordionButton sx={styles.accordionBtn}>
              Posso pedir pensão antes mesmo do bebê nascer?
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel sx={styles.accordionPanel}>
            <Text>
              SIM. Mas para isso será preciso reunir indícios de paternidade,
              comprovação de união estável, por exemplo, deste modo será
              possível pleitear os denominados alimentos gravídicos, que deverão
              ser pagos durante a gestação.
            </Text>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem borderTopWidth={0} borderBottomColor={"transparent"}>
          <h2>
            <AccordionButton sx={styles.accordionBtn}>
              Acordo informal "de boca" tem validade?
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel sx={styles.accordionPanel}>
            <Text>
              NÃO. Apesar de muitas famílias optarem pelos acordos informais, é
              importante regulamentar na justiça questões como a guarda dos
              menores, para evitar problemas futuros. Acordo informal (de boca)
              não tem validade jurídica, e não pode ser executado em caso de
              descumprimento.
            </Text>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Flex>
  );
}

const styles = {
  accordionBtn: {
    w: "100%",
    fontFamily: "Poppins",
    textAlign: { base: "left", lg: "left" },
    justifyContent: { base: "space-between" },
    border: "1px solid #ffb600",
    mb: "4px",
    mt: "12px",
    px: {
      base: "12px",
    },
    fontSize: {
      base: "12px",
      lg: "20px",
    },
    py: {
      base: "2px",
      lg: "6px",
    },
  },
  accordionPanel: {
    py: {
      base: "4px",
      lg: "12px",
    },
    // maxH: {
    //   base: "100px",
    //   lg: "200px",
    // },
    fontSize: { base: "12px", lg: "22px" },
    overflowY: "auto",
  },
};
