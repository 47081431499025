export const styles = {
  listItem: {
    backgroundColor: "#F7A933",
    borderRadius: "8px",
    marginBottom: "40px",
    // pl: { base: "12px", lg: "22px" },
    // pr: { base: "4px", lg: "32px" },
    py: "12px",
    // pr: { base: "8px", lg: "4px" },
    maxW: "900px",
    w: "100%",
    fontSize: { base: "14px", lg: "22px" },
    textAlign: "justify",
    fontWeight: "bold",
    height: "60px",
    overflowY: "visible",
    px: "12px",
  },
  listItemIcon: {
    height: "100%",
    minWidth: "64px",
    marginLeft: { base: "-44px", lg: "-54px" },
    color: "#F7A933",
    fontWeight: "bold",
    fontSize: { base: "44px", lg: "6xl" },
  },
  listItemIconImage: {
    height: "120%",
    width: { base: "90px", lg: "100px" },
    mr: "12px",
    marginLeft: { base: "14px", lg: "20px" },
    color: "#F7A933",
    fontWeight: "bold",
  },
  text: {
    fontSize: { base: "14px", lg: "2xl" },
  },
};
