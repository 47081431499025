import {
  Center,
  Heading,
  VStack,
  Image,
  Text,
  Flex,
  Button,
  Icon,
  Link,
} from "@chakra-ui/react";
import "../App.css";
import SectionA from "../LpFamiliaComponents/sectiona";
import SectionB from "../LpFamiliaComponents/sectionb";
import SectionC from "../LpFamiliaComponents/sectionc";
import SectionF from "../LpFamiliaComponents/sectionf";
import SectionG from "../LpFamiliaComponents/sectiong";
import { motion } from "framer-motion";
import WppButton from "../LpFamiliaComponents/wppbutton";
import SectionE from "../LpFamiliaComponents/sectione";
import SectionH from "../LpFamiliaComponents/sectionh";
import { PhoneIcon } from "@chakra-ui/icons";
import logooab from "../assets/logooab.webp";
import { Helmet } from "react-helmet";

const styles = {
  listItem: {
    backgroundColor: "#575757",
    borderRadius: "8px",
    marginBottom: "20px",
    pl: { base: "12px", lg: "22px" },
    pr: { base: "22px", lg: "32px" },
    py: "12px",
    maxW: "900px",
    fontSize: { base: "12px", lg: "22px" },
    textAlign: "justify",
  },
  listItemIcon: {
    height: "100%",
    minWidth: "64px",
    marginLeft: { base: "-44px", lg: "-54px" },
    color: "#F7A933",
    fontWeight: "bold",
    fontSize: { base: "44px", lg: "6xl" },
  },
  listItemIconImage: {
    height: "100%",
    width: { base: "200px", lg: "250px" },
    mr: "22px",
    marginLeft: { base: "-34px", lg: "-70px" },
    color: "#F7A933",
    fontWeight: "bold",
  },
  text: {
    fontSize: { base: "14px", lg: "2xl" },
  },
  lastListItemText: {
    fontSize: {
      base: "16px",
      lg: "20px",
    },
  },
  stripe: {
    fontSize: { base: "11.5px", md: "20px", xl: "24px" },
    lineHeight: { base: "16px", md: "20px", xl: "30px" },
    textAlign: "center",
    textOverflow: "clip",
    fontWeight: "bold",
    maxW: "900px",
  },
};

function LpFamilia() {
  return (
    <div className="App">
      <Helmet>
        <meta name="description" content="Direito de família" />
      </Helmet>

      <SectionA />
      <Center
        bg="brand.300"
        px="20px"
        minH={{ base: "200px" }}
        pt={{ base: "100px", xl: "0" }}
      >
        <Text sx={styles.stripe}>
          Estamos disponíveis durante todo o processo, com a transparência de
          informações e aconselhamento em cada etapa.
        </Text>
      </Center>
      <SectionG />
      <SectionB />
      <Center p="30px 20px">
        <Text sx={styles.stripe}>
          VOCÊ PRECISA DE ESPECIALISTA E NÃO DE AMADORES!
        </Text>
      </Center>
      <SectionH />
      <SectionC />
      <Center
        id="direitos"
        bg="white"
        p={{ base: "100px 28px 40px", lg: "100px 100px 80px 100px" }}
        fontWeight={300}
        flexDir={"column"}
        color="#000"
      >
        <Heading
          fontSize={{ base: "lg", lg: "4xl" }}
          mb="38px"
          textAlign={"center"}
          fontWeight={"thin"}
          fontFamily={"Poppins"}
        >
          <b>Seus direitos em poucos dias, usando apenas o celular.</b>
        </Heading>

        <Flex direction={"column"}>
          <WppButton
            title="FALAR COM ADVOGADO"
            showCirculeButton
            customBgColor="brand.300"
            fontWeight={"bold"}
            description="Atendimento em todo Brasil 100% online no WhatsApp"
          />
        </Flex>
      </Center>
      <SectionE />
      <SectionF />
      <Center
        bg="white"
        color="black"
        pt="40px"
        pb="8px"
        position="relative"
        top="-2px"
      >
        <VStack px="22px" mb="30px">
          <Heading
            mb="12px"
            fontSize={{ base: "20px", lg: "28px" }}
            color="#F7A933"
            fontFamily={"Poppins"}
          >
            VOCÊ SABIA?
          </Heading>
          <VStack
            textAlign={"justify"}
            spacing={"20px"}
            maxW={"860px"}
            fontSize={"20px"}
            mb="20px"
          >
            <Text sx={styles.lastListItemText}>
              Em razão da Pandemia de Covid-19, o Conselho Nacional de Justiça
              criou o <b>“Juízo 100% Digital”</b> e, assim, todos os processos
              podem serem realizados on-line e à distância.
            </Text>
            <Text sx={styles.lastListItemText}>
              Ou seja: <b>AGORA TUDO É DIGITAL e PELA INTERNET</b>, inclusive as
              ações e recursos das decisões que você sofreu. É por isso que
              nossa contratação, a assinatura de documentos,{" "}
              <span style={{ backgroundColor: "#e38100" }}>
                o envio dos materiais e nosso pagamento são todos realizados
                pela internet.
              </span>
            </Text>
            <Text sx={styles.lastListItemText}>
              <b>
                Você não perderá seu tempo com nada. É rápido, simples e
                totalmente seguro.
              </b>{" "}
              Então não importa onde você mora, pois basicamente{" "}
              <b>estarei ao seu lado e basta 1 clique para falar comigo.</b>
            </Text>
          </VStack>
        </VStack>
      </Center>
      <Center
        w="full"
        h={{ base: "500px", lg: "800px", "2xl": "880px" }}
        bgColor="#171717"
      >
        <VStack
          w="full"
          maxW="1040px"
          alignItems={"center"}
          p={{ base: "0px 12px" }}
          textAlign={"center"}
          spacing={{ base: 4, lg: 4 }}
          color="white"
        >
          <Center
            w="full"
            maxW={{ base: "280px", lg: "500px", "2xl": "600px" }}
          >
            <Image
              src={logooab}
              loading="lazy"
              alt="logo com marca da ordem dos advogados(OAB)"
              w="auto"
              objectFit={"contain"}
              height={{ base: "auto", lg: "230px" }}
              mt="-20px"
            />
          </Center>
          <Flex
            direction={"row"}
            as={motion.div}
            initial={{ x: -100, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            lineHeight={{ base: "16px", lg: "28px", "2xl": "30px" }}
            fontSize={{ base: "11px", lg: "20px", "2xl": "28px" }}
            textOverflow={"clip"}
          >
            <Text
              mx={{ base: "12px", xl: "82px" }}
              p={{ base: "20px", xl: "30px" }}
              borderRadius={"8px"}
              border="1px solid white"
            >
              ATENDIMENTO <br /> RÁPIDO
            </Text>
            <Text
              mx={{ base: "12px", xl: "82px" }}
              p={{ base: "20px", xl: "30px" }}
              borderRadius={"8px"}
              border="1px solid white"
            >
              10 ANOS DE
              <br /> EXPERIÊNCIA
            </Text>
          </Flex>
          <Flex direction={"column"} pt={{ base: 16, lg: 28 }}>
            <WppButton
              title="FALAR COM ADVOGADO"
              showCirculeButton
              customBgColor="brand.300"
              fontWeight={"bold"}
              mb="4px"
            />
          </Flex>
        </VStack>
      </Center>
      <Center bg="white" w="full" py="40px">
        <Text
          fontSize={{ base: "14px", lg: "16px" }}
          noOfLines={2}
          textAlign="center"
        >
          copyright © 2024 Hernandes Teixeira Advogado
          <br /> CNPJ 25.108.902/0001-77
        </Text>
      </Center>
      <Center bg="black" py="40px" px="18px" flexDirection={"column"}>
        <Text
          textAlign={"center"}
          fontSize={{ base: "12px" }}
          maxW="800px"
          color="white"
        >
          <b>AVISO LEGAL:</b> Este site não é um produto Meta Platforms, Inc.,
          Google LCC. e não oferece serviços públicos oficiais do governo.
          Trabalhamos exclusivamente com serviços jurídicos de acordo com a
          legislação vigente e o Código de Ética e Disciplina da OAB do Brasil.
        </Text>
      </Center>
      <Button
        as={Link}
        isExternal
        href="https://wa.me/5565999137973?text=Ol%C3%A1!+Desejo+ser+atendido%20por+um+Advogado+Especialista+em+Direito+de+Fam%C3%ADlia"
        id="circle-wpp-button"
        borderWidth={"3px"}
        borderColor={"white"}
        size="sm"
        bg="green.400"
        h={{ base: "60px", lg: "80px" }}
        w={{ base: "60px", lg: "80px" }}
        _hover={{
          transform: "scale(1.05)",
        }}
        zIndex={9999}
        borderRadius={"full"}
        position={"fixed"}
        bottom={5}
        right={5}
      >
        <Icon
          as={PhoneIcon}
          color="white"
          h={{ base: "30px", lg: "40px" }}
          w={{ base: "30px", lg: "40px" }}
        />
      </Button>
    </div>
  );
}

export default LpFamilia;
