import "react-multi-carousel/lib/styles.css";
import { Center, Flex, VStack, Text } from "@chakra-ui/react";
import { motion } from "framer-motion";
import WppButton from "./wppbutton";
// import Carousel from "react-multi-carousel";
import { useEffect } from "react";

// const responsive = {
//   desktop: {
//     breakpoint: { max: 3000, min: 1024 },
//     items: 3,
//     slidesToSlide: 1, // optional, default to 1.
//   },
//   tablet: {
//     breakpoint: { max: 1024, min: 464 },
//     items: 2,
//     slidesToSlide: 2, // optional, default to 1.
//   },
//   mobile: {
//     breakpoint: { max: 464, min: 0 },
//     items: 1,
//     slidesToSlide: 1, // optional, default to 1.
//   },
// };

// const srcList: string[] = [
//   "/assets/g(20).webp",
//   "/assets/g(11).webp",
//   "/assets/g(9).webp",
//   "/assets/g.webp",
//   "/assets/g(1).webp",
//   "/assets/g(2).webp",
//   "/assets/g(3).webp",
//   "/assets/g(4).webp",
//   "/assets/g(5).webp",
//   "/assets/g(6).webp",
//   "/assets/g(7).webp",
//   "/assets/g(8).webp",
//   "/assets/g(10).webp",
//   "/assets/g(12).webp",
//   "/assets/g(13).webp",
//   "/assets/g(14).webp",
//   "/assets/g(15).webp",
//   "/assets/g(16).webp",
//   "/assets/g(17).webp",
//   "/assets/g(18).webp",
//   "/assets/g(19).webp",
// ];

export default function SectionG() {
  // const [loaded, setLoaded] = useState<boolean>(false);
  // const [elem, setElem] = useState<HTMLCollectionOf<Element>>();

  // const Car = useCallback(() => {
  //   // const items = elem ? Array.from(elem) : [];
  //   return (
  //     <Carousel
  //       customLeftArrow={<div className="leftArrow" />}
  //       customRightArrow={<div className="rightArrow" />}
  //       swipeable
  //       draggable
  //       arrows
  //       showDots={true}
  //       responsive={responsive}
  //       infinite={true}
  //       autoPlay
  //       autoPlaySpeed={6000}
  //       keyBoardControl={true}
  //       customTransition="all .5"
  //       transitionDuration={500}
  //       containerClass="carousel-container-padding"
  //       removeArrowOnDeviceType={["tablet", "mobile"]}
  //       dotListClass="custom-dot-list-style"
  //       itemClass="carousel-item-padding-40-px"
  //     >
  //       {srcList.map((src, index) => (
  //         <div
  //           key={index}
  //           className="review-item"
  //           // dangerouslySetInnerHTML={{ __html: el.innerHTML }}
  //         >
  //           <Image
  //             onClick={() => {
  //               const a = document.createElement("a");

  //               a.href = "https://g.co/kgs/tgrr8W";
  //               a.target = "_blank";
  //               a.click();
  //             }}
  //             src={src}
  //           />
  //         </div>
  //       ))}
  //     </Carousel>
  //   );
  // }, []);

  useEffect(() => {
    function load() {
      const els = document.getElementsByClassName("review-item")!;
      // setElem(els);

      if (els.length === 0) {
        setTimeout(() => {
          load();
        }, 2000);
      } else {
        // setLoaded(true);
      }
    }

    load();
  }, []);
  return (
    <Center w="full" bgColor="white">
      <VStack
        w="full"
        maxW="1040px"
        alignItems={"center"}
        p={{ base: "42px 12px" }}
        textAlign={"center"}
        spacing={{ base: 4, lg: 8 }}
        color="#171717"
      >
        <Text
          lineHeight={{ base: "38px", lg: "44px", "2xl": "60px" }}
          fontSize={{ base: "34px", lg: "32px", "2xl": "50px" }}
          pl="4px"
          mb={{ base: "10px", "2xl": "20px" }}
          maxW={{ base: "420px", xl: "700px" }}
          fontWeight={"bold"}
        >
          VEJA O QUE DIZEM SOBRE O NOSSO ESCRITÓRIO
        </Text>
        <Flex
          as={"iframe"}
          mt={{ base: "-20px", lg: "0px" }}
          title="google-ratings"
          src="https://cdn.trustindex.io/amp-widget.html#63cef1223c4246029706b4366ac"
          sandbox="allow-scripts allow-same-origin"
          height="360"
          width={{ base: "98vw", lg: "900px" }}
        >
          {/* <span></span> */}
        </Flex>
        {/* <Car /> */}
        {/* <div className="google-carousel" style={{ display: "none" }}></div> */}
        <Text fontSize={{ base: "11px", md: "13px" }}>
          Avaliação totalizada <b>Google 4.9</b> de 5, com base em{" "}
          <b>160 avaliações</b>
        </Text>
        <Flex direction={"column"}>
          <WppButton
            title="FALAR COM ADVOGADO"
            showCirculeButton
            customBgColor="brand.300"
            fontWeight={"bold"}
            mb="4px"
          />
        </Flex>
        <Text
          as={motion.div}
          initial={{ x: -100, opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          color="#171717"
          lineHeight={{ base: "16px", lg: "28px", "2xl": "30px" }}
          fontSize={{ base: "11px", lg: "20px", "2xl": "26px" }}
          textOverflow={"clip"}
        >
          <b>
            Tome a melhor decisão o quanto antes e conte com seu acompanhamento
            jurídico especializado para preservar os seus interesses.
          </b>
        </Text>
      </VStack>
    </Center>
  );
}
