import { Center, VStack } from "@chakra-ui/react";

export default function ErrorPage() {
  //   const error = useRouteError();

  return (
    <Center id="error-page" h="100vh">
      <VStack maxW={"400px"}>
        <h1>Oops!</h1>
        <p>Desculpe. Houve um erro inesperado.</p>
        {/* <Text color="red.500">{(error as any)?.message as string}</Text> */}
      </VStack>
    </Center>
  );
}
